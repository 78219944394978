import request from '../request.js';
import public_request from '../public_request';
// 客户数据列表的方法
export function importClients(data) {
    return request({
        url: '/data-management/clients',
        method: 'GET',
        params: public_request(data),
    });
}
// 移除上传客户
export function removeClients(data) {
    return request({
        url: '/data-management/clients',
        method: 'DELETE',
        data: public_request(data),
    });
}
// 移除上传无法导入客户
export function removeClientsInvalid(data) {
    return request({
        url: '/data-management/clients-invalid',
        method: 'DELETE',
        data: public_request(data),
    });
}
// 客户一键去重
export function removeClientDuplicate(data) {
    return request({
        url: '/data-management/clients-duplicate',
        method: 'DELETE',
        data: public_request(data),
    });
}
// 单条导入上传客户
export function singeImportClient(data) {
    return request({
        url: '/data-management/clients',
        method: 'post',
        data: public_request(data),
    });
}
// 全部导入上传客户
export function allImportClient(data) {
    return request({
        url: '/data-management/clients-all',
        method: 'POST',
        data: public_request(data),
    });
}
