<template>
    <div class="import-table">
        <div class="title-tip">
            总共
            <font style="color:#D9001B;">{{ importClue.total }}</font>
            条，其中：老线索
            <font style="color:#D9001B;">
                {{ importClue.existCount }}
            </font>条，重复
            <font style="color:#D9001B;">
                {{ importClue.duplicateCount }}
            </font>条，无法导入
            <font style="color:#D9001B;">
                {{ importClue.invalidCount }}
            </font>条。tips：当老线索、及关联客户有归属时，导入不会改变原归属
        </div>
        <el-card class="table-area">
            <div class="btn-area flex-sb">
                <div>
                    <el-button
                        type="danger"
                        :disabled="clueSelectId.length == 0"
                        size="mini"
                        @click="removeClientData"
                    >移除</el-button>
                    <el-button type="warning" size="mini" @click="removeCantImport">移除无法导入</el-button>
                    <el-button type="primary" size="mini" @click="removeSameData">一键去重</el-button>
                </div>
                <div>
                    <el-radio-group v-model="timeTabs" size="mini" @change="changeClueType">
                        <el-radio-button label="0">全部</el-radio-button>
                        <el-radio-button label="1">重复号码</el-radio-button>
                        <el-radio-button label="2">无法导入线索</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <!-- 列表区域 -->
            <el-table
                ref="multipleTable"
                v-loading="loading"
                :data="importClue.list"
                border
                style="width: 100%;margin-top:10px"
                :height="tableHeight"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="序号" type="selection" width="55" />
                <el-table-column label="序号" type="index" width="55" />
                <el-table-column label="客户姓名" prop="name" width="200" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div class="flex">
                            <div style>{{ scope.row.name }}</div>

                            <div v-show="scope.row.exist == 1" class="new">老客户</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="电话号码" prop="mobileNumber" width="120">
                    <template slot-scope="scope">
                        <font
                            :style="{ color: scope.row.duplicate == 1 ? '#D9001B' : '' }"
                        >{{ scope.row.mobileNumber }}</font>
                    </template>
                </el-table-column>
                <el-table-column label="性别" prop="sex" width="55" />
                <el-table-column label="年龄" prop="age" width="80">
                    <template slot-scope="scope">{{ scope.row.age ? scope.row.age + "岁" : "未知" }}</template>
                </el-table-column>
                <el-table-column label="居住地" prop="residence" min-width="100" />
                <el-table-column label="资金需求" prop="fundNeeds" width="120" />
                <el-table-column label="资质" prop min-width="130">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.hasHouse == 1"
                            class="icon house"
                            style="margin-right: 10px"
                        >房</span>
                        <span
                            v-if="scope.row.hasVehicle == 1"
                            class="icon car"
                            style="margin-right: 10px"
                        >车</span>
                        <span
                            v-if="scope.row.hasShebao == 1"
                            style="margin-right: 10px"
                            class="icon shebao"
                        >社</span>
                        <span
                            v-if="scope.row.hasGongjijin == 1"
                            class="icon gongjijin"
                            style="margin-right: 10px"
                        >金</span>
                        <span
                            v-if="scope.row.hasBaodan == 1"
                            style="margin-right: 10px"
                            class="icon baodan"
                        >保</span>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="remark" :show-overflow-tooltip="true" />
            </el-table>
            <el-pagination
                style="margin-top:10px;text-align:right"
                :current-page="status.pageNo"
                :page-size="status.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="importClue.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <div class="bottom-area">
            <el-button size="small" @click="backToIndex">上一步</el-button>
            <el-button
                type="primary"
                :disabled="clueSelectId.length == 0"
                size="small"
                @click="toImportClue(0)"
            >导入</el-button>
            <el-button type="primary" size="small" @click="toImportClue(1)">全部导入</el-button>
        </div>
        <import-dialog ref="importDialog" @submitForm="submitForm" />
    </div>
</template>

<script>
import {
    importClients,
    removeClients,
    removeClientsInvalid,
    removeClientDuplicate,
    singeImportClient,
    allImportClient,
} from '@/api/data-manage/importClient';
export default {
    components: {
        'import-dialog': () => import('../../components/import-dialog'),
    },
    data() {
        return {
            timeTabs: 0,
            importClue: {},
            loading: true,
            status: {
                batchNo: '',
                category: '',
                pageNo: 1,
                pageSize: 10,
            },
            clueSelectId: [],
            tableHeight: window.innerHeight - 310,
            type: '',
        };
    },
    // 列表高度固定
    created() {
        window.addEventListener('resize', this.getHeight);
    },
    // 列表高度固定
    beforeDestroy() {
        window.removeEventListener('resize', this.getHeight);
    },
    methods: {
        // 列表高度固定
        getHeight() {
            // this.tableHeight = this.tableData.length > 0 ? window.innerHeight - 250 : "";
            this.tableHeight = window.innerHeight - 310;
        },
        // 从父组件调用的方法传值
        init(obj) {
            if (obj) {
                this.status.batchNo = obj.batchNo;
                this.getImportTable();
            }
        },
        // 获取导入数据
        async getImportTable() {
            importClients(this.status).then((res) => {
                // console.log(res);
                if (res.code == 0) {
                    this.loading = true;
                    this.importClue = res.data;
                    this.importClue.list.forEach((item) => {
                        // 性别
                        let clientSex = this.common.allValueNeed('sex');
                        clientSex.forEach((i) => {
                            if (item.sex == i.number) {
                                item.sex = i.cnVal;
                            }
                        });
                    });

                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 切换线索类型
        changeClueType() {
            this.status.category = this.timeTabs == 0 ? '' : this.timeTabs;
            (this.status.pageNo = 1), (this.status.pageSize = 10);
            this.getImportTable();
        },
        //分页选择
        handleSizeChange(val) {
            this.status.pageSize = val;
            this.getImportTable();
        },
        // 页码切换
        handleCurrentChange(val) {
            this.status.pageNo = val;
            this.getImportTable();
        },
        // 列表多选时间
        handleSelectionChange(val) {
            this.clueSelectId = val;
        },
        // 移除选中线索
        removeClientData() {
            let selectIds = this.clueSelectId.map((x) => x.id);
            removeClients({ ids: selectIds }).then((res) => {
                console.log(res);
                if (res.code == 0) {
                    this.$message.success('客户已移除！');
                    this.getImportTable();
                }
            });
        },
        // 移除无法导入
        removeCantImport() {
            removeClientsInvalid({ batchNo: this.status.batchNo }).then(
                (res) => {
                    if (res.code == 0) {
                        this.$message.success('已移除无法导入！');
                        this.getImportTable();
                    }
                }
            );
        },
        // 一键去重
        removeSameData() {
            removeClientDuplicate({ batchNo: this.status.batchNo }).then(
                (res) => {
                    if (res.code == 0) {
                        this.$message.success('已一键去重！');
                        this.getImportTable();
                    }
                }
            );
        },
        // 导入数据
        toImportClue(type) {
            if (type == 1) {
                this.$refs.multipleTable.clearSelection();
                this.clueSelectId = [];
            }
            this.$refs.importDialog.init(2);
            this.type = type;
        },
        // 子组件弹窗返回的数据
        submitForm(val) {
            let data = {
                target: val.target,
                departmentId: val.staffId ? '' : val.departmentId,
                staffId: val.staffId,
            };
            // 选择导入
            if (this.type == 0) {
                // 导入操作
                let selectIds = this.clueSelectId.map((x) => x.id);
                data.batchNo = this.status.batchNo;
                data.ids = selectIds;
                // console.log(data);
                singeImportClient(data).then((res) => {
                    if (res.code == 0) {
                        if (res.data.notImportedCount) {
                            this.$message.success(
                                '已导入' +
                                    res.data.importedCount +
                                    '条，未导入' +
                                    res.data.notImportedCount +
                                    '条，请检查员工客户数量上限'
                            );
                        } else {
                            this.$message.success(
                                '已导入' + res.data.importedCount + '条'
                            );
                        }
                        this.getImportTable();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                data.batchNo = this.status.batchNo;
                // 全部导入操作
                allImportClient(data).then((res) => {
                    if (res.code == 0) {
                        if (res.data.notImportedCount) {
                            this.$message.success(
                                '已导入' +
                                    res.data.importedCount +
                                    '条，未导入' +
                                    res.data.notImportedCount +
                                    '条，请检查员工客户数量上限'
                            );
                        } else {
                            this.$message.success(
                                '已导入' + res.data.importedCount + '条'
                            );
                        }
                        this.getImportTable();
                        if (!res.data.notImportedCount) {
                            this.backToIndex();
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        // 返回上一页
        backToIndex() {
            this.timeTabs = 0;
            this.status.category = '';
            this.$emit('toIndex');
        },
    },
};
</script>

<style lang="less" scoped>
@import '@/css/table.less';
.flex {
    display: flex;
}
/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #909399;
}
.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.import-table {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    .title-tip {
        font-size: 14px;
        color: #333;
        font-weight: 400;
    }
    .table-area {
        margin-top: 10px;
        height: 90%;
        padding: 10px;
    }
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #4086ec;
        background-color: #ecf3fd;
        box-shadow: 0 0px 0px #ccc;
        border-color: #dcdfe6;
    }
    /deep/ .el-radio-button__inner {
        color: #aaa;
    }
}
.bottom-area {
    text-align: center;
    margin-top: 10px;
}
.new {
    background-color: #ff8040;
    color: #fff;
    padding: 0 10px;
    border-radius: 10px;
    margin-left: 2%;
}
</style>
